:root {
  --box-width: 600px;
  --title-bg: #282c34;
  --content-bg: #fbf7ea;
}

.App {
}

.Social-container {
  position: absolute;
  width: calc(var(--box-width)/4);
  bottom: 12px;
  opacity: 1;
  transition-delay: 2s;
  transition-property: opacity;
  transition-duration: 1.5s;
}

.Social-fade {
  display: flex;
  justify-content: space-evenly;
  opacity: 0;
  animation: fadeIn 1.5s;
  animation-delay: 4.5s;
  animation-fill-mode: forwards;
}

.Back-arrow-box {
  position: absolute;
  left: 20px;
  top: calc(50px - 0.65em);
  transform: rotate(-135deg);
  opacity: 1;
}

.Back-arrow {
  position: relative;
  border-style: solid;
  border-width: 0.15em 0.15em 0 0;
  display: inline-block;
  height: 0.65em;
  width: 0.65em;
  opacity: 0;
  transition: 0.5s;
}

.Body-base {
  position: relative;
  padding: 8px;
  background-color: var(--content-bg);
  min-height: calc(100vh - 116px);
  max-height: calc(100vh - 116px);
  height: calc(100vh - 116px);
  overflow: auto;
  overflow-x: hidden;
  display: flex;
  flex: 1;
  justify-content: center;
  font-family: 'Quattrocento Sans';
  font-size: calc(10px + 2vmin);
  color: #414044;
  z-index: 0;
  /*box-shadow: inset 0px 5px 3px -3px rgba(0, 0, 0, 0.6);*/
}

.Body-content {
  position: relative;
  padding: 5px;
  width: 100%;
  max-width: 880px;
  display: flex;
  flex: 1;
  justify-content: flex-start;
  flex-direction: column;
}

.Body-about {
  font-size: 0.8em;
  opacity: 0;
  transition: opacity 1s;
  margin-bottom: 1.5em;
}

.Body-profile {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  font-size: 0.8em;
}

.Body-kph {
  display: flex;
  flex-direction: column;
  margin-left: 0.5em;
  margin-right: 0.5em;
  opacity: 0;
  transform: translateY(15px);
  transition: opacity 1s, transform 1s;
  transition-delay: 2s;
}

.Body-kpm {
  display: flex;
  flex-direction: column;
  margin-left: 0.5em;
  margin-right: 0.5em;
  opacity: 0;
  transform: translateY(15px);
  transition: opacity 1s, transform 1s;
  transition-delay: 3s;
}

.Kpm-desc {
  margin-top: 15px;
  margin-bottom: 15px;
}

.Kph-desc {
  margin-top: 15px;
  margin-bottom: 15px;
}

.Site-title {
  font-size: calc(12px + 2vmin);
  text-align: center;
  padding-bottom: 0.35em;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: normal;
}

.Fe-title {
  font-weight: bold;
  text-transform: uppercase;
  font-size: calc(3px + 2vmin);
  padding: 1px;
  margin-top: 0;
  margin-bottom: 0;
}

.Be-title {
  font-weight: bold;
  text-transform: uppercase;
  font-size: calc(3px + 2vmin);
  padding: 1px;
  margin-top: 0;
  margin-bottom: 0;
}

.Body-base img {
  width: 350px;
  max-width: 100%; 
  max-height: 450px;
  box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.4);
  transition: box-shadow 0.5s;
}

.Body-base img:hover {
  box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.5);
}

.Title-header {
  position: sticky;
  top: 0;
  background-color: var(--title-bg);
  min-height: 100px;
  display: flex;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  transition: none;
  box-shadow: 0px -2px 8px 3px rgba(0, 0, 0, 0.6);
  z-index: 1;
}

.Title-container {
  width: var(--box-width);
  position: absolute;
  transition: top 2s, transform 2s;
}

.Title-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Square-logo {
  position: absolute;
  height: 95px;
  animation: fadeIn 1s, squareExpand 2s;
  animation-delay: 0s, 1s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
  overflow: hidden;
  opacity: 0;
  z-index: 2;
  border-left: solid white; /*safari*/
  border-right: solid white; /*safari*/
}

.Square-clip {
  position: absolute;
  height: 95px;
  width: 100%;
  background-color: var(--title-bg);
  animation: clipExpand 2s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
  z-index: 2;
}

.Square-sub {
  position: relative;
  height: 41px;
  top: 84px;
  background-color: var(--title-bg);
  animation-name: squareClose;
  animation-duration: 2s;
  animation-delay: 2.7s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
  overflow: hidden;
  border-left: solid rgba(255,255,255,0); /*safari*/
  border-right: solid rgba(255,255,255,0); /*safari*/
  z-index: 2;
}

.Square-hid {
  position: relative;
  height: 15px;
  top: 84px;
  opacity: 1;
  background-color: var(--title-bg);
  animation-name: squareHidden;
  animation-duration: 2s;
  animation-delay: 2.7s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
  overflow: hidden;
  z-index: 2;
}

.block {
  position: absolute;
}

.sub-block {
  position: relative;
  top: -23px;
  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 1.5s;
  animation-delay: 3.5s;
  animation-fill-mode: forwards;
  transition: none;
  /*border: 2px white solid;*/
}

.name {
  position: relative;
  white-space: nowrap;
  text-align: center;
  font-family: "Forum";
  font-size: 96px;
  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 1.8s;
  animation-delay: 1.7s;
  animation-fill-mode: forwards;
  /*animation-timing-function: linear;*/
  text-shadow: 2px 2px 6px rgba(0,0,0,0.4);
  z-index: 1;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: normal;
}

.sub-left {
  position: absolute;
  white-space: nowrap;
  width: 200px;
  left: 40px;
  text-align: center;
  font-family: "Forum";
  font-size: 48px;
  /*animation-timing-function: linear;*/
  text-shadow: 2px 2px 6px rgba(0,0,0,0.4);
  transition: none;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: normal;
}

.sub-right {
  position: absolute;
  white-space: nowrap;
  width: 200px;
  right: 40px;
  text-align: center;
  font-family: "Forum";
  font-size: 48px;
  /*animation-timing-function: linear;*/
  text-shadow: 2px 2px 6px rgba(0,0,0,0.4);
  transition: 2s;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: normal;
}

.link-left {
  transition: transform 0.5s;
}

.link-right {
  transition: transform 0.5s;
}

.sub-div {
  position: absolute;
  white-space: nowrap;
  top: 100px;
  width: 100%;
  text-align: center;
  font-family: "Forum";
  font-size: 48px;
  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 1.5s;
  animation-delay: 3s;
  animation-fill-mode: forwards;
  /*animation-timing-function: linear;*/
  text-shadow: 2px 2px 6px rgba(0,0,0,0.4);
}

.Body-base a {
  color: #414044;
  text-decoration: none;
}

@keyframes squareExpand {
  from {
    width: 0%;
    border-left: 6px solid rgba(255,255,255,1);
    border-right: 6px solid rgba(255,255,255,1)
  }
  to {
    width: 100%; 
    border-left: 6px solid rgba(255,255,255,0);
    border-right: 6px solid rgba(255,255,255,0);
  }
}

@keyframes clipExpand {
  from {
    clip-path: polygon(0 0, 0 100%, 50% 100%, 50% 0, 50% 0, 50% 100%, 0 100%, 100% 100%, 100% 0%);
  }
  to {
    clip-path: polygon(0 0, 0 100%, 0 100%, 0 0, 100% 0, 100% 100%, 0 100%, 100% 100%, 100% 0%);
  }
}

@keyframes squareBgExpand {
  from {
    width: 50%;
  }
  to {
    width: 0%; 
  }
}

@keyframes squareClose {
  from {
    width: 100%;
    border-left: 2px solid rgba(255,255,255,0);
    border-right: 2px solid rgba(255,255,255,0);
  }
  to {
    width: 0%;
    border-left: 2px solid rgba(255,255,255,1);
    border-right: 2px solid rgba(255,255,255,1);
  }
}

@keyframes squareHidden {
  from {
    width: 100%;
  }
  to {
    width: 0%;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@media only screen and (max-width: 320px) {
  :root {
    --box-width: 94vw;
  }
  .Title-header {
    min-height: 60px;
  }
  .Body-base {
    min-height: calc(100vh - 76px);
    max-height: calc(100vh - 76px);
    height: calc(100vh - 76px);
  }
  .Back-arrow-box {
    top: calc(30px - 0.65em);
  }
  .name {
    font-size: 48px;
  }
  .sub-block {
    top: -12px;
  }
  .sub-left, .sub-right, .sub-div {
    font-size: 22px;
  }
  .sub-left {
    left: -25px;
  }
  .sub-right {
    right: -25px;
  }
  .Square-sub {
    top: 40px;
    height: 28px;
  }
  .Square-hid {
    top: 40px;
    height: 3px;
  }
  .Square-logo {
    height: 50px;
  }
}

@media only screen and (min-width: 321px) and (max-width: 600px) {
  :root {
    --box-width: 94vw;
  }
  .Title-header {
    min-height: 70px;
  }
  .Body-base {
    min-height: calc(100vh - 86px);
    max-height: calc(100vh - 86px);
    height: calc(100vh - 86px);
  }
  .Back-arrow-box {
    top: calc(35px - 0.65em);
  }
  .name {
    font-size: 60px;
  }
  .sub-block {
    top: -12px;
  }
  .sub-left, .sub-right, .sub-div {
    font-size: 32px;
  }
  .sub-left {
    left: -15px;
  }
  .sub-right {
    right: -15px;
  }
  .Square-sub {
    top: 50px;
    height: 28px;
  }
  .Square-hid {
    top: 50px;
    height: 3px;
  }
  .Square-logo {
    height: 70px;
  }
}